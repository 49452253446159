<template>
<div class="poster">
  <el-dialog title="" width="500px" :show-close="false"  @close="closeModel" :visible.sync="showModel">
    <div class="title">{{posterInfo.commodityName}}</div>
    <div class="picture">
      <img :src="posterInfo.commodityImage">
    </div>
    <div class="infoList">
      <div class="info" v-for="item in posterInfo.commoditySkuList" :key="item.id">
        <div class="configuration">{{item.propertiesInnerShow}}</div>
        <div class="price">
          <div class="month">限时活动价：<span class="red">￥{{item.minimumRent}}</span> /月</div>
          <div class="old">原价：￥{{item.minimumRent}}</div>
        </div>
      </div>
    </div>
    <div class="consult">
      <div class="img">
        <img src="../../assets/imgs/wxqrcode.png">
      </div>
      <div class="tips">
        长按进入小程序了解更多，更多问题请咨询专属业务经理
      </div>
    </div>
  </el-dialog>
</div>
</template>
<script>
import {createPoster} from "@/api/category";

export default {
  name:'poster',
  data(){
    return{
      showModel:false,
      commodityId:'',
      posterInfo:{}
    }
  },
  methods:{
    openModel(commodityId){
      this.commodityId = commodityId
      this.showModel = true
      createPoster({commodityId:this.commodityId}).then(res=>{
        if(res.data.code == 200){
          this.posterInfo = res.data.data
        }
      })
    },
    closeModel(){
      this.showModel = false
    }
  }
}
</script>

<style scoped lang="scss">
.poster{
  .title{
    text-align: center;
    font-size: 30px;
  }
  .picture{
    img{
      width: 450px;
    }
  }
  .infoList{
    .info{
      margin-bottom: 30px;
      .configuration{
        font-size: 18px;
        margin-bottom: 20px;
      }
      .price{
        .month{
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 20px;
          .red{
            color: red;
          }
        }
        .old{
          text-decoration: line-through;
          color: #6a737d;
        }
      }
    }
  }
  .consult{
    width: 70%;
    margin: 0px auto;
    display: flex;
    img{
      width: 100px;
      height: 100px;
      margin-right: 20px;
    }
    .tips{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>